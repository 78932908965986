import {formatCurrency} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem} from "../../../components";
import React from "react";
import {TableCell} from "../../../components/Table/TableCell";
import {LegalAgreement} from "../../models/InvestmentProgram";
import classNames from "classnames";
import {getInEstateTotalValueForInvestmentProgram} from "../common/AssetSummaryCalculator";

type AssetSummaryLegalAgreementProps = {
    legalAgreement: LegalAgreement;
    onClickEdit: (id: string | null) => void;
    onClickViewHoldings: (id: string) => void;
    renderLegalAgreementDetails: (legalAgreement: LegalAgreement) => React.ReactNode;
    actionsDisabled: boolean;
    gridClassName: string;
    hideActionMenu?: boolean

};
export const LegalAgreementRow = ({
                                      legalAgreement,
                                      onClickEdit,
                                      onClickViewHoldings,
                                      renderLegalAgreementDetails,
                                      actionsDisabled,
                                      gridClassName,
                                      hideActionMenu = false
                                  }: AssetSummaryLegalAgreementProps) => {
    const presentValue = legalAgreement.marketValue ? getInEstateTotalValueForInvestmentProgram([legalAgreement], []) : 0;

    return (<div key={`legal-agreement-row-${legalAgreement.id}`}
                 role="row"
                 className={classNames("assets-grid-table-row", gridClassName)}
                 tabIndex={0}
                 aria-label={legalAgreement.name}>
        {renderLegalAgreementDetails(legalAgreement)}
        <TableCell text={formatCurrency(presentValue)} className="textalign-right"/>
        {!hideActionMenu &&
            <TableActionDropdownMenu ariaLabel={`${legalAgreement.name} Menu`}
                                     disabled={actionsDisabled}>
                <DropdownItem
                    itemText="Edit Account"
                    value="Edit Account"
                    onClick={() => onClickEdit(legalAgreement.id)}
                />
                <DropdownItem
                    itemText="Edit Holdings"
                    value="View Holdings"
                    onClick={() => onClickViewHoldings(legalAgreement.id)}
                />
            </TableActionDropdownMenu>}
    </div>);
};
