import {formatCurrency} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem, Icon} from "../../../components";
import React from "react";
import {TableCell} from "../../../components/Table/TableCell";
import {HeldAwayAccount} from "../../models/InvestmentProgram";
import classNames from "classnames";
import {DropdownGroup} from "xps-react";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

type AssetSummaryHeldAwayAccountProps = {
    heldAwayAccount: HeldAwayAccount,
    onClickEdit: (id: string | null) => void;
    onClickViewHoldings: (id: string) => void;
    onClickDelete: (heldAwayAccount: HeldAwayAccount) => void;
    renderHeldAwayAccountDetails: (heldAwayAccount: HeldAwayAccount) => React.ReactNode;
    actionsDisabled: boolean;
    gridClassName: string;
    hideActionMenu?: boolean
};
export const HeldAwayAccountRow = ({
                                       heldAwayAccount,
                                       onClickEdit,
                                       onClickViewHoldings,
                                       onClickDelete,
                                       renderHeldAwayAccountDetails,
                                       actionsDisabled,
                                       gridClassName,
                                       hideActionMenu = false
                                   }: AssetSummaryHeldAwayAccountProps) => {

    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const presentValue = heldAwayAccount.baseMarketValue ? heldAwayAccount.baseMarketValue : 0;

    return (<div key={`held-away-row-${heldAwayAccount.id}`}
                 role="row"
                 className={classNames("assets-grid-table-row", gridClassName)}
                 tabIndex={0}
                 aria-label={heldAwayAccount.financialAccountName}>
        {renderHeldAwayAccountDetails(heldAwayAccount)}
        <TableCell text={formatCurrency(presentValue)} className="textalign-right"/>
        {!hideActionMenu &&
            <TableActionDropdownMenu ariaLabel={`${heldAwayAccount.financialAccountName} Menu`}
                                     disabled={actionsDisabled}>
                <DropdownGroup groupName=" ">
                    <DropdownItem
                        itemText="Edit HAA Account"
                        value="Edit Account"
                        onClick={() => onClickEdit(heldAwayAccount.id)}
                        disabled={true}
                    />
                    <DropdownItem
                        itemText="View HAA Holdings"
                        value="View Holdings"
                        onClick={() => onClickViewHoldings(heldAwayAccount.id)}
                        disabled={true}
                    />
                </DropdownGroup>
                <DropdownGroup groupName=" ">
                    <DropdownItem
                        itemText="Delete Account"
                        value="Delete Account"
                        disabled={isProfileWithProposalsOrArchived}
                        onClick={() => onClickDelete(heldAwayAccount)}
                    >
                        <Icon name="delete" className="account-table-delete-icon"/>
                    </DropdownItem>
                </DropdownGroup>
            </TableActionDropdownMenu>}
    </div>);
};