import React, {useState} from "react";
import {AssetsSummary} from "../../models/Assets";
import Assets from "./Assets";
import PersonalLiabilities from "./Liabilities";
import {InvestorGroupType, MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import {ReorderAssetRequest} from "./ReorderAssets";
import {DraggableLocation, DropResult} from "react-beautiful-dnd";
import {GeneralInflow} from "../../models/GeneralInflow";
import {SocialSecurity} from "../../models/SocialSecurity";
import {PersonalAsset} from "../../models/PersonalAsset";
import {
    assetListData,
    sortByOrdinalAndCashValue,
    sortByOrdinalAndInEstateOrMarketValue,
    sortByOrdinalAndLoanBalance,
    sortByOrdinalAndPresentValue
} from "../common/utils";

type ArrangeAssetsProps = {
    profileId: string
    assetsSummary: AssetsSummary;
    memberGroup: MemberGroup;
    investorGroup: InvestorGroupType;
    onSave: (request: ReorderAssetRequest) => void;
    onCancel: () => void;
}

const ArrangeAssets = ({
                           profileId,
                           assetsSummary,
                           memberGroup,
                           investorGroup,
                           onSave,
                           onCancel
                       }: ArrangeAssetsProps) => {

    const {
        inEstateStandaloneAccounts,
        inEstateLegalAgreements,
        inEstateHeldAwayAccounts,
        inEstatePartiallyOwnedInvestmentAccounts,
        inEstatePersonalAssets,
        inEstateLifeInsurances,
        inEstatePersonalLiabilities
    } = assetListData(assetsSummary);

    const [accountIds, setAccountIds] = useState<string[]>(sortByOrdinalAndInEstateOrMarketValue([...inEstateLegalAgreements, ...inEstateStandaloneAccounts, ...inEstateHeldAwayAccounts]).map(a => a.id))
    const [personalAssetIds, setPersonalAssetIds] = useState<string[]>(inEstatePersonalAssets ? (sortByOrdinalAndPresentValue(inEstatePersonalAssets) as PersonalAsset[]).map(a => a.id) : [])
    const [generalInflowIds, setGeneralInflowIds] = useState<string[]>(assetsSummary.generalInflows ? (sortByOrdinalAndPresentValue(assetsSummary.generalInflows.data) as GeneralInflow[]).map(a => a.id).filter((id): id is string => id !== undefined) : [])
    const [socialSecurityIds, setSocialSecurityIds] = useState<string[]>(assetsSummary.socialSecurities ? (sortByOrdinalAndPresentValue(assetsSummary.socialSecurities.data) as SocialSecurity[]).map(a => a.id).filter((id): id is string => id !== undefined) : [])
    const [lifeInsuranceIds, setLifeInsuranceIds] = useState<string[]>(inEstateLifeInsurances ? (sortByOrdinalAndCashValue(inEstateLifeInsurances).map(a => a.id)) : [])
    const [personaLiabilityIds, setPersonaLiabilityIds] = useState<string[]>(sortByOrdinalAndLoanBalance(inEstatePersonalLiabilities).map(a => a.id))

    const [reorderAssetRequest, setReorderAssetRequest] = useState<ReorderAssetRequest>({
        accounts: accountIds,
        personalAssets: personalAssetIds,
        generalInflows: generalInflowIds,
        socialSecurities: socialSecurityIds,
        lifeInsurances: lifeInsuranceIds,
        personalLiabilities: personaLiabilityIds
    });

    const captureAccountsOrder = ({source, destination}: DropResult) => {
        if (destination) {
            const newIdOrder = captureAssetIdOrder(source, destination, accountIds);
            setAccountIds(newIdOrder);
            setReorderAssetRequest({...reorderAssetRequest, accounts: newIdOrder})
        }
    }

    const capturePersonalAssetsOrder = ({source, destination}: DropResult) => {
        if (destination) {
            const newIdOrder = captureAssetIdOrder(source, destination, personalAssetIds);
            setPersonalAssetIds(newIdOrder);
            setReorderAssetRequest({...reorderAssetRequest, personalAssets: newIdOrder})
        }
    }

    const captureGeneralInflowsOrder = ({source, destination}: DropResult) => {
        if (destination) {
            const newIdOrder = captureAssetIdOrder(source, destination, generalInflowIds);
            setGeneralInflowIds(newIdOrder);
            setReorderAssetRequest({...reorderAssetRequest, generalInflows: newIdOrder})
        }
    }

    const captureSocialSecuritiesOrder = ({source, destination}: DropResult) => {
        if (destination) {
            const newIdOrder = captureAssetIdOrder(source, destination, socialSecurityIds);
            setSocialSecurityIds(newIdOrder);
            setReorderAssetRequest({...reorderAssetRequest, socialSecurities: newIdOrder})
        }
    }

    const captureLifeInsurancesOrder = ({source, destination}: DropResult) => {
        if (destination) {
            const newIdOrder = captureAssetIdOrder(source, destination, lifeInsuranceIds);
            setLifeInsuranceIds(newIdOrder);
            setReorderAssetRequest({...reorderAssetRequest, lifeInsurances: newIdOrder})
        }
    }

    const capturePersonaLiabilitiesOrder = ({source, destination}: DropResult) => {
        if (destination) {
            const newIdOrder = captureAssetIdOrder(source, destination, personaLiabilityIds);
            setPersonaLiabilityIds(newIdOrder);
            setReorderAssetRequest({...reorderAssetRequest, personalLiabilities: newIdOrder})
        }
    }

    return (
        <>
            <DataEntryHeader
                title="Arrange Assets"
                primaryButtonText="Save"
                onPrimaryButtonClick={() => onSave(reorderAssetRequest)}
                secondaryButtonText="Cancel"
                onSecondaryButtonClick={onCancel}
            />
            <div className="paddingtop-md paddingright-xxxl paddingleft-xxxl">
                <Assets
                    profileId={profileId}
                    accounts={inEstateStandaloneAccounts}
                    legalAgreements={inEstateLegalAgreements}
                    heldAwayAccounts={inEstateHeldAwayAccounts}
                    partiallyOwnedInvestmentAccounts={inEstatePartiallyOwnedInvestmentAccounts}
                    personalAssets={inEstatePersonalAssets}
                    generalInflows={assetsSummary.generalInflows}
                    socialSecurities={assetsSummary.socialSecurities}
                    lifeInsurances={inEstateLifeInsurances}
                    memberGroup={memberGroup}
                    investorGroup={investorGroup}
                    captureAccountsOrder={captureAccountsOrder}
                    capturePersonalAssetsOrder={capturePersonalAssetsOrder}
                    captureGeneralInflowsOrder={captureGeneralInflowsOrder}
                    captureSocialSecuritiesOrder={captureSocialSecuritiesOrder}
                    captureLifeInsurancesOrder={captureLifeInsurancesOrder}
                />
                <div className="paddingbottom-20"/>
                <PersonalLiabilities
                    totalLiabilitiesValue={assetsSummary.totalLiabilitiesValue}
                    personalLiabilities={inEstatePersonalLiabilities}
                    capturePersonaLiabilitiesOrder={capturePersonaLiabilitiesOrder}
                />
            </div>
        </>
    );
}

const captureAssetIdOrder = (source: DraggableLocation, destination: DraggableLocation, assetIdArray: any[]) => {
    const reordered = [...assetIdArray];
    const [reorderedItem] = reordered.splice(source.index, 1);
    reordered.splice(destination.index, 0, reorderedItem);
    return reordered;
}

export default ArrangeAssets;