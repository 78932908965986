import React, {useContext, useEffect, useState} from "react";
import {assetsApiClient} from "../AssetsApiClient";
import {useHistory, useParams} from "react-router-dom";
import {EquityCompensationForm} from "./EquityCompensationForm";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {EquityCompensationFormData,} from "../models/EquityCompensation";
import EditFormContainer from "../EditFormContainer";
import {RouteWithProfileIdAndAssetId} from "../../routes/types";
import AssetsViewContext from "../common/AssetsViewContext";
import {wealthManagementApiClient} from "../../ClientManagement/WealthManagementApiClient";

const EditEquityCompensation: React.FC = () => {
    const history = useHistory();
    const {profileId, assetId: equityCompId} = useParams<RouteWithProfileIdAndAssetId>();
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [equityComp, setEquityComp] = useState<EquityCompensationFormData>();
    const viewType = useContext(AssetsViewContext);

    useEffect(() => {
        let componentUnmounted = false;

        Promise.all([
            clientManagementApiClient.getMemberGroup(profileId),
            assetsApiClient.getEquityCompensation(profileId, equityCompId)
        ]).then(([memberGroupResponse, equityCompAsset]) => {
            if (componentUnmounted) return;
            setMemberGroup(memberGroupResponse);
            setEquityComp(equityCompAsset)
        }).catch(error => console.error('Could not fetch equity compensation data', error.message));

        return () => {
            componentUnmounted = true;
        }
    }, [profileId, equityCompId])

    if (!memberGroup || !equityComp) {
        return <LoadingIndicator/>;
    }

    const onSaveClick = async (formData: EquityCompensationFormData) => {
        const request: EquityCompensationFormData = {
            ...formData,
            id: equityComp.id,
            profileId: profileId
        }
        return await assetsApiClient.putEquityCompensation(request)
    };

    const onDelete = async () => {
        const response = await assetsApiClient.deleteEquityCompensation(profileId, equityCompId);
        await wealthManagementApiClient.deleteAssetRelianceStack(profileId, equityCompId)
        if (response.status === 200) {
            history.push(`/Profile/${profileId}/ClientProfile/${viewType}`);
        }
    }

    return <div className='equity-compensation'>
        <EditFormContainer
            modalTitle="Asset"
            assetType="Equity Compensation"
            handleDelete={onDelete}
            assetDescription={equityComp.entityOrAccountName}
            form={(handleCancel) => (
                <EquityCompensationForm
                    handleCancel={handleCancel}
                    handleSave={onSaveClick}
                    initialEquityComp={equityComp}
                    memberGroup={memberGroup}
                    formatTitle={description => `Edit ${description}`}
                />
            )}
        />
    </div>;
};

export default EditEquityCompensation;