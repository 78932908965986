import React, {useContext, useEffect, useState} from 'react';
import {PersonalAsset, UnsavedPersonalAsset} from "../models/PersonalAsset";
import PersonalAssetForm from "./PersonalAssetForm";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithAssetId} from "../../routes/types";
import {assetsApiClient} from "../AssetsApiClient";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import EditFormContainer from "../EditFormContainer";
import {mapPersonalAsset} from "./mappers";
import {mapToOwnershipDetailsFormData} from "../Ownership/mappers";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {LegalEntityFormData} from "../models/Ownership";
import AssetsViewContext from "../common/AssetsViewContext";
import {wealthManagementApiClient} from "../../ClientManagement/WealthManagementApiClient";

const EditPersonalAsset = () => {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const profile = useAppSelector(selectProfile);
    const [personalAsset, setPersonalAsset] = useState<PersonalAsset>();
    const {assetId: personalAssetId} = useParams<RouteWithAssetId>();
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [legalEntities, updateLegalEntities] = useState<LegalEntityFormData[]>();

    useEffect(() => {
        Promise.all([
            assetsApiClient.getPersonalAsset(profile.id, personalAssetId),
            clientManagementApiClient.getMemberGroup(profile.id),
            assetsApiClient.getLegalEntities(profile.id)
        ])
            .then(([personalAssetResponse, memberGroupResponse, legalEntitiesResponse]) => {
                setPersonalAsset(personalAssetResponse);
                setMemberGroup(memberGroupResponse);
                updateLegalEntities(legalEntitiesResponse);
            }).catch(error => console.error('Could not fetch personal asset data', error.message));

    }, [personalAssetId, history.location.pathname]);

    const handleSave = async (unsavedData: UnsavedPersonalAsset) => {
        const response = await assetsApiClient.postPersonalAsset({
            id: personalAssetId,
            profileId: profile.id,
            ...unsavedData
        });
        if (response.status === 201) {
            history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
        }
    };

    const handleDelete = async () => {
        const response = await assetsApiClient.deletePersonalAsset(profile.id, personalAssetId);
        await wealthManagementApiClient.deleteAssetRelianceStack(profile.id, personalAssetId)
        if (response.status === 200) {
            history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
        }
    };

    return <>
        {personalAsset ?
            <div className='personal-asset'>
                <EditFormContainer
                    modalTitle="Asset"
                    assetType="Personal Asset"
                    assetDescription={personalAsset.description}
                    handleDelete={handleDelete}
                    form={(handleCancel) => (
                        <PersonalAssetForm
                            formatTitle={description => `Edit ${description}`}
                            handleSave={handleSave}
                            handleCancel={handleCancel}
                            defaultPersonalAsset={mapPersonalAsset(personalAsset)}
                            defaultOwnershipDetails={mapToOwnershipDetailsFormData(personalAsset)}
                            memberGroup={memberGroup!}
                            legalEntities={legalEntities!}
                            updateLegalEntities={updateLegalEntities}
                        />
                    )}
                    liabilities={personalAsset.personalLiabilities && personalAsset.personalLiabilities.map(
                        liability => liability.description
                    )}
                />
            </div>
            :
            <LoadingIndicator/>
        }
    </>;
}
export default EditPersonalAsset;


