import {
    FamilyTreeChartData,
    FamilyTreeLink,
    FamilyTreeMemberNodeType,
    FamilyTreeNode,
    FamilyTreeNodeStyle,
    FamilyTreePartnerRelationshipGroupNodeDataType,
    FamilyTreeType,
    NodeOnClickType,
    TemplateCategory
} from "../models/FamilyTreeType";
import {FamilyRelationshipType, RelationshipStatusType} from "../models/FamilyRelationshipType";
import {LifeStatus, MemberType} from "../models/MemberType";
import {findChildren, findExtendedParents, isChildOf} from "../FamilyRelationshipService";
import {FamilyMode} from "./AddFamilyMember/FamilyMode";

/**
 * Generates family tree chart data for the current node. Recursively processes all family members for the current node.
 *
 * Algorithm:
 *  Create node for current family member
 *  Process partner if exists
 *  Process current family member's children (joint or otherwise)
 *  Process partner's single children (if partner exists)
 *
 * @param familyMember The current member to process.
 * @param nodeOnClick A callback for when any node is clicked.
 * @param generation The generation of the current family member. Will be 0 for the root node.
 * @param groupTemplateId The group id for which to add any generated nodes.
 * @param siblingGroup Nodes of the same level are grouped according to common parents.
 * @param primaryContact The primary contact within this family tree.
 * @param parents The immediate parents of this member.
 */
const generateFamilyTreeChartData = (
    familyMember: MemberType,
    nodeOnClick: NodeOnClickType,
    primaryContact: MemberType,
    generation = 0,
    groupTemplateId: TemplateCategory | string = TemplateCategory.IMMEDIATE_FAMILY,
    siblingGroup = SiblingGroupPosition.LEFT,
    ...parents: MemberType[]
): FamilyTreeChartData => {
    // Initialize the family tree chart data (nodes & links)
    let familyTreeChartData: FamilyTreeChartData = {
        familyTreeNodeData: [],
        familyTreeLinkData: []
    };

    // Base Case
    // If familyMember.id is null, this is the end of the recursion
    if (!familyMember.id) {
        return familyTreeChartData;
    }
    let {familyTreeNodeData, familyTreeLinkData} = familyTreeChartData;

    const accumulate = (chartData: FamilyTreeChartData) => {
        ({familyTreeNodeData, familyTreeLinkData} = familyTreeChartData = {
            familyTreeNodeData: familyTreeNodeData.concat(chartData.familyTreeNodeData),
            familyTreeLinkData: familyTreeLinkData.concat(chartData.familyTreeLinkData)
        });
    }

    // Create the node data representing this family member
    const familyMemberNodeData: FamilyTreeMemberNodeType = createFamilyTreeNode(familyMember, {
        nodeOnClick,
        relationshipType: getGenerationalRelationshipType(generation),
        generation: generation,
        parentRelationshipType: getParentRelationshipType(...parents),
        siblingGroup: siblingGroup,
        groupId: groupTemplateId
    });
    familyTreeNodeData.push(familyMemberNodeData);

    // Lookup the partner of this family member
    const partnerRelation = getMemberPartnerRelation(familyMember);

    const partner: MemberType | undefined = partnerRelation?.fromMember;

    const lateralRelationshipGroupId = createLateralRelationshipGroupId(familyMember, partnerRelation?.fromMember);

    if (partnerRelation) {
        // If the family member has a partner, add both members to a group
        familyMemberNodeData.group = lateralRelationshipGroupId;
        // Create the node data representing this family member's partner
        const partnerNodeData: FamilyTreeMemberNodeType = createFamilyTreeNode(partnerRelation.fromMember, {
            nodeOnClick,
            groupId: lateralRelationshipGroupId,
            generation: generation,
            relationshipType: partnerRelation.type,
            siblingGroup: siblingGroup,
        });
        familyTreeNodeData.push(partnerNodeData);
        // Add the lateral link between the family member and their partner
        familyTreeLinkData.push(createFamilyTreeLink(familyMemberNodeData.key, partnerNodeData.key));
        if (lateralRelationshipGroupId) {
            // Add the group node in order to present the family member and their partner at the same level in the tree
            familyTreeNodeData.push(createFamilyTreeLateralRelationshipGroup(
                lateralRelationshipGroupId,
                familyMemberNodeData,
                siblingGroup,
                generation,
                groupTemplateId,
            ));
        }
    } else if (generation === -1) {
        familyMemberNodeData.group = groupTemplateId;
    }

    // All children of the current family member, single or joint
    const children: MemberType[] = findChildren(familyMember);
    for (const child of children) {
        const parentsOfChild = getParentsOfChild(child, familyMember, partnerRelation?.fromMember);

        const childFamilyTreeChartData = generateFamilyTreeChartData(
            child,
            nodeOnClick,
            primaryContact,
            generation + 1,
            groupTemplateId,
            getSiblingGroup(parentsOfChild, partner, primaryContact),
            ...parentsOfChild
        );

        accumulate(childFamilyTreeChartData);

        if (partner && isChildOf(child.id, partner)) { // Dual Parent
            familyTreeLinkData.push(
                ...parentsOfChild.map((parent) =>
                    // groupId cannot be undefined because we know we have two parents
                    createFamilyTreeLink(parent.id, lateralRelationshipGroupId!)),
                createFamilyTreeLink(lateralRelationshipGroupId!, child.id),
            );
        } else { // Single Parent
            familyTreeLinkData.push(...parentsOfChild.map((parent) => createFamilyTreeLink(parent.id, child.id)));
        }
    }

    // Children of the family member's partner
    findChildren(partner)
        .filter(child => !isChildOf(child.id, familyMember))
        .forEach(child => {
                const childFamilyTreeChartData = generateFamilyTreeChartData(
                    child,
                    nodeOnClick,
                    primaryContact,
                    generation + 1,
                    groupTemplateId,
                    getSiblingGroup(parents, partner, primaryContact),
                    partner!
                );

                accumulate(childFamilyTreeChartData);
                familyTreeLinkData.push(createFamilyTreeLink(partner!.id, child.id));
            }
        );

    return familyTreeChartData;
};

export const generateFamilyTreeNodeData = (
    familyTreeData: FamilyTreeType,
    nodeOnClick: NodeOnClickType,
    onAddOtherMembersClick: () => void,
    showOtherMembers: boolean,
    showAddOtherMembersButton: boolean,
    showExtendedFamily: boolean = false,
    isProfileWithProposalsOrArchived: boolean = false
): FamilyTreeChartData => {
    const familyTreeChartData = generateFamilyTreeChartData(
        familyTreeData.primaryContact,
        nodeOnClick,
        familyTreeData.primaryContact,
        0,
        TemplateCategory.IMMEDIATE_FAMILY,
        SiblingGroupPosition.LEFT
    );
    // Create group for Immediate Family
    familyTreeChartData.familyTreeNodeData.push({
        key: TemplateCategory.IMMEDIATE_FAMILY,
        isGroup: true,
        category: TemplateCategory.IMMEDIATE_FAMILY,
        centerRelativeToPartKey: TemplateCategory.EXTENDED_FAMILY,
    });
    if (showOtherMembers) {
        familyTreeData.primaryContact.otherMembers
            .forEach((relationship) => {
                familyTreeChartData.familyTreeNodeData.push(
                    createFamilyTreeNode(relationship.fromMember, {
                        nodeOnClick: nodeOnClick,
                        generation: 0,
                        relationshipType: FamilyRelationshipType.OTHER,
                        // sibling group is irrelevant for this scenario
                        siblingGroup: SiblingGroupPosition.CENTER,
                        groupId: TemplateCategory.OTHER_MEMBERS_CONTENT,
                    })
                );
            });

        if(!isProfileWithProposalsOrArchived) {
            // Create group for Other Members section
            familyTreeChartData.familyTreeNodeData.push({
                key: TemplateCategory.OTHER_MEMBERS_WRAPPER,
                isGroup: true,
                category: TemplateCategory.OTHER_MEMBERS_WRAPPER,
                centerRelativeToPartKey: TemplateCategory.IMMEDIATE_FAMILY,
            });

            // Create group for Other Members Content Area
            familyTreeChartData.familyTreeNodeData.push({
                key: TemplateCategory.OTHER_MEMBERS_CONTENT,
                isGroup: true,
                category: TemplateCategory.OTHER_MEMBERS_CONTENT,
                group: TemplateCategory.OTHER_MEMBERS_WRAPPER
            });
        }
        else {
            // Create group for Other Members section
            familyTreeChartData.familyTreeNodeData.push({
                key: TemplateCategory.OTHER_MEMBERS_WRAPPER,
                isGroup: true,
                category: TemplateCategory.OTHER_MEMBERS_WRAPPER,
                centerRelativeToPartKey: TemplateCategory.IMMEDIATE_FAMILY,
            });

            if(familyTreeData.primaryContact.otherMembers.length !== 0) {
                // Create group for Other Members Content Area
                familyTreeChartData.familyTreeNodeData.push({
                    key: TemplateCategory.OTHER_MEMBERS_CONTENT,
                    isGroup: true,
                    category: TemplateCategory.OTHER_MEMBERS_CONTENT,
                    group: TemplateCategory.OTHER_MEMBERS_WRAPPER
                });
            }
        }

        if (showAddOtherMembersButton) {
            familyTreeChartData.familyTreeNodeData.push({
                key: TemplateCategory.ADD_OTHER_MEMBERS_BUTTON,
                name: "ADD OTHER MEMBER",
                category: TemplateCategory.ADD_OTHER_MEMBERS_BUTTON,
                group: TemplateCategory.OTHER_MEMBERS_WRAPPER,
                style: FamilyTreeNodeStyle.BUTTON,
                onClick: onAddOtherMembersClick,
                centerRelativeToPartKey: TemplateCategory.OTHER_MEMBERS_CONTENT,
            });
        }
    }

    if (showExtendedFamily) {
        familyTreeChartData.familyTreeNodeData.push({
            key: TemplateCategory.EXTENDED_FAMILY,
            isGroup: true,
            category: TemplateCategory.EXTENDED_FAMILY,
        });

        const partnerOfPrimary = getMemberPartnerRelation(familyTreeData.primaryContact)?.fromMember;

        if (hasExtendedFamily(familyTreeData.primaryContact, partnerOfPrimary)) {
            const primaryLinkNodeData = getFamilyTreeLinkNodeDataForMemberWithExtendedFamily(familyTreeData.primaryContact, nodeOnClick, SiblingGroupPosition.LEFT, familyTreeData.primaryContact);
            familyTreeChartData.familyTreeNodeData.push(...primaryLinkNodeData.nodeData)
            familyTreeChartData.familyTreeLinkData.push(...primaryLinkNodeData.linkData)
            if (partnerOfPrimary) {
                const partnerLinkNodeData = getFamilyTreeLinkNodeDataForMemberWithExtendedFamily(partnerOfPrimary, nodeOnClick, SiblingGroupPosition.RIGHT, familyTreeData.primaryContact);
                familyTreeChartData.familyTreeNodeData.push(...partnerLinkNodeData.nodeData)
                familyTreeChartData.familyTreeLinkData.push(...partnerLinkNodeData.linkData)
            }
        }
    }

    return familyTreeChartData;
};

export enum SiblingGroupPosition {
    LEFT = 0,
    CENTER = 1,
    RIGHT = 2
}

const handleSingleParent = (parent: MemberType, partnerOfParent: MemberType | undefined, primaryContact: MemberType) => {
    // To determine the position of children, we need to know if the parent has a partner relationship
    if (partnerOfParent) {
        if (parent.age > partnerOfParent.age || parent.id === primaryContact.id) return SiblingGroupPosition.LEFT;
        if (parent.age < partnerOfParent.age) return SiblingGroupPosition.RIGHT;
        if (parent.firstName > partnerOfParent.firstName) return SiblingGroupPosition.RIGHT;
        if (parent.firstName < partnerOfParent.firstName) return SiblingGroupPosition.LEFT;
        if (parent.id > partnerOfParent.id) return SiblingGroupPosition.RIGHT;
        if (parent.id < partnerOfParent.id) return SiblingGroupPosition.LEFT;
    }
    return SiblingGroupPosition.LEFT;
}
export const getSiblingGroup = (parents: MemberType[], partnerOfParent: MemberType | undefined, primaryContact: MemberType) => {
    return parents.length === 1 ? handleSingleParent(parents[0], partnerOfParent, primaryContact) : SiblingGroupPosition.CENTER;
}

const createNodeClickHandler = (familyMember: MemberType, nodeOnClick: NodeOnClickType, familyMode: FamilyMode = FamilyMode.IMMEDIATE) => () => nodeOnClick(familyMember, familyMode);

type FamilyTreeNodeOptions = {
    nodeOnClick: NodeOnClickType,
    groupId?: string,
    generation: number
    relationshipType: FamilyRelationshipType | undefined,
    parentRelationshipType?: RelationshipStatusType,
    siblingGroup: number,
};
export const determineFamilyMode = (relationshipType: FamilyRelationshipType | undefined, generation: number): FamilyMode => {
    if(generation < 0) {
        return FamilyMode.EXTENDED;
    } else if(relationshipType === FamilyRelationshipType.OTHER) {
        return FamilyMode.OTHER;
    } else {
        return FamilyMode.IMMEDIATE;
    }
}
export const createFamilyTreeNode = (familyMember: MemberType, {
    nodeOnClick,
    groupId,
    generation,
    relationshipType,
    parentRelationshipType,
    siblingGroup
}: FamilyTreeNodeOptions): FamilyTreeMemberNodeType => {
    const isGenerationZero = generation === 0;
    const isPrimary = isGenerationZero && !relationshipType;
    const middleInitial = familyMember.middleInitial ? (" " + familyMember.middleInitial + ". ") : " ";
    const nodeData: FamilyTreeMemberNodeType = {
        key: familyMember.id,
        name: familyMember.firstName + middleInitial + familyMember.lastName,
        subtitle: familyMember.age + (familyMember.stateAbbr ? ", " + familyMember.stateAbbr : ''),
        age: familyMember.age,
        primary: isPrimary,
        style: FamilyTreeNodeStyle.DEFAULT,
        group: groupId,
        relationshipType: relationshipType,
        parentRelationshipType: parentRelationshipType,
        siblingGroup: siblingGroup,
        generation: generation,
        onClick: createNodeClickHandler(familyMember, nodeOnClick, determineFamilyMode(relationshipType, generation))
    };

    if (isPrimary) {
        nodeData.style = FamilyTreeNodeStyle.PRIMARY_CONTACT;
    } else {
        if (isGenerationZero && (
            nodeData.relationshipType === FamilyRelationshipType.SPOUSE
            || nodeData.relationshipType === FamilyRelationshipType.DOMESTIC_PARTNER
        )) {
            nodeData.style = FamilyTreeNodeStyle.PRIMARY_LEGAL_PARTNER;
        } else if (nodeData.relationshipType === FamilyRelationshipType.EX_SPOUSE) {
            nodeData.style = FamilyTreeNodeStyle.DOTTED;
            nodeData.subtitle = nodeData.subtitle + " Ex-Spouse";
        }
        if (familyMember.lifeStatus === LifeStatus.Deceased) {
            nodeData.style = FamilyTreeNodeStyle.DECEASED;
            nodeData.subtitle = '';
        }
    }

    return nodeData;
};

const createFamilyTreeLateralRelationshipGroup = (
    relationshipId: string,
    familyMemberNodeData: FamilyTreeMemberNodeType,
    siblingGroup: number,
    generation: number,
    groupTemplateId: TemplateCategory | string,
): FamilyTreePartnerRelationshipGroupNodeDataType => ({
    key: relationshipId,
    isGroup: true,
    name: familyMemberNodeData.name,
    age: familyMemberNodeData.age,
    siblingGroup: siblingGroup,
    generation: generation,
    category: TemplateCategory.PARTNER_RELATIONSHIP,
    group: groupTemplateId,
});

const createFamilyTreeLink = (sourceNodeKey: string, targetNodeKey: string): FamilyTreeLink => ({
    from: sourceNodeKey,
    to: targetNodeKey,
});

const createLateralRelationshipGroupId = (...familyMembers: (MemberType | undefined)[]): string | undefined => {
    return createLateralRelationshipGroupIdWithPrefix('', familyMembers);
};
const createLateralRelationshipGroupIdWithPrefix = (prefix: string, familyMembers: (MemberType | undefined)[]): string | undefined => {
    const filteredMembers = familyMembers.filter((familyMember): familyMember is MemberType =>
        !!familyMember && !!familyMember.id);
    return filteredMembers.length > 1
        ? prefix + filteredMembers.map((familyMember) => familyMember.id).sort().join(':')
        : undefined;
};

const createLateralRelationshipGroupSimple = (prefix: string, familyMembers: MemberType[]): string => {
    return prefix + familyMembers.map((familyMember) => familyMember.id).sort().join(':');
};

const hasExtendedFamily = (familyMember: MemberType | undefined, partner: MemberType | undefined) => {
    return findExtendedParents(familyMember).length > 0 || findExtendedParents(partner).length > 0;
}

const getMemberPartnerRelation = (member: MemberType) => {
    return member.family.find((familyRelationship) =>
        familyRelationship.type !== FamilyRelationshipType.CHILD && familyRelationship.type !== FamilyRelationshipType.PARENT);
};

const getGenerationalRelationshipType = (generation: number): FamilyRelationshipType | undefined => {
    let relationshipType: FamilyRelationshipType | undefined = undefined;
    // A positive generation is intended to represent descendants, while a negative generation would indicate ancestors
    if (generation > 0) {
        relationshipType = FamilyRelationshipType.CHILD;
    } else if (generation < 0) {
        relationshipType = FamilyRelationshipType.PARENT;
    }
    return relationshipType;
}

const getParentRelationshipType = (...parents: MemberType[]): RelationshipStatusType | undefined => {
    let relationshipType: RelationshipStatusType | undefined = undefined;
    if (parents.length === 1) {
        relationshipType = RelationshipStatusType.SINGLE_PARENT;
    } else if (parents.length > 1) {
        relationshipType = RelationshipStatusType.PARTNERED;
    }
    return relationshipType;
}

const isMemberParentOfChild = (member: MemberType, child: MemberType): boolean =>
    member.family.some((familyRelationship) =>
        familyRelationship.fromMember.id === child.id
        && familyRelationship.type === FamilyRelationshipType.CHILD
    );

const getParentsOfChild = (child: MemberType, ...potentialParents: (MemberType | undefined)[]): MemberType[] =>
    potentialParents
        .filter((potentialParent): potentialParent is MemberType => !!potentialParent?.id)
        .filter((potentialParent) => isMemberParentOfChild(potentialParent, child));

const getFamilyTreeLinkNodeDataForMemberWithExtendedFamily = (member: MemberType, nodeOnClick: NodeOnClickType, groupPosition: SiblingGroupPosition, primaryContact: MemberType) => {
    const parentMembers: MemberType[] = findExtendedParents(member);
    const parentMemberKeys = parentMembers.map(parentMember => parentMember.id);
    const parentMembersGroupId = createLateralRelationshipGroupSimple('member-parents:', parentMembers);

    const primaryParent = parentMembers.find(m => m.primaryParent);
    const primaryParentLateral = primaryParent?.family.find(r => parentMemberKeys.includes(r.fromMember.id))?.fromMember;
    const parentsAreLateralPartners = primaryParent && primaryParentLateral;

    const nodeData: FamilyTreeNode[] = [];
    const linkData: FamilyTreeLink[] = [];

    if (primaryParent && parentMembersGroupId) {
        //add member-parents group to wrap one side of extended family
        const middleInitial = primaryParent.middleInitial ? (" " + primaryParent.middleInitial + ". ") : " "
        nodeData.push(createFamilyTreeLateralRelationshipGroup(
            parentMembersGroupId,
            {
                age: primaryParent.age,
                name: primaryParent.firstName + middleInitial + primaryParent.lastName
            } as FamilyTreeMemberNodeType,
            groupPosition,
            -1,
            TemplateCategory.EXTENDED_FAMILY,
        ));
    }

    if (parentsAreLateralPartners) {
        //add parent relationship and linkages
        const lateralRelationshipGroupId1 = createLateralRelationshipGroupId(primaryParent, primaryParentLateral);
        if (lateralRelationshipGroupId1) {
            linkData.push(createFamilyTreeLink(lateralRelationshipGroupId1, member.id));
            linkData.push(createFamilyTreeLink(primaryParent.id, lateralRelationshipGroupId1));
            linkData.push(createFamilyTreeLink(primaryParentLateral.id, lateralRelationshipGroupId1));
        }
    }

    for (const parentMember of parentMembers) {
        if (parentMember.id === primaryParentLateral?.id) {
            continue;
        }

        const parentMemberFamilyTreeChartData = generateFamilyTreeChartData(
            parentMember,
            nodeOnClick,
            primaryContact,
            -1,
            parentMembersGroupId,
            groupPosition
        );

        nodeData.push(...parentMemberFamilyTreeChartData.familyTreeNodeData)
        linkData.push(...parentMemberFamilyTreeChartData.familyTreeLinkData)

        if (!parentsAreLateralPartners) {
            linkData.push(createFamilyTreeLink(parentMember.id, member.id));
        }
    }
    return {nodeData, linkData};

};