import {AssetsSummary, CurrentNetWorthAsset} from "../../models/Assets";
import {COLOR_LIFE_INSURANCES} from "../../../constants/colors";
import {AssetAccordionHeader} from "../../AssetSummary/common/AssetAccordionHeader";
import {AccordionItemWithActionMenu} from "../../../components";
import React, {useContext} from "react";
import {LifeInsurance} from "../../models/LifeInsurance";
import {
    calculateCNWAssetsJointlyOwnedTotalPresentValue,
    calculateCNWAssetsMemberOwnedTotalPresentValue,
    calculateJointlyOwnedValue,
    calculateMemberOwnedValue, calculateTotalValue
} from "../../AssetSummary/common/AssetSummaryCalculator";
import {assetListData} from "../../AssetSummary/common/utils";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetAccordionContentHeader} from "../../AssetSummary/common/AssetAccordionContentHeader";
import {TableCell} from "../../../components/Table/TableCell";
import {LifeInsuranceRow} from "../../AssetSummary/InEstate/LifeInsuranceRow";
import {getOwnershipDescription} from "./ownershipUtils";
import {useHistory} from "react-router-dom";
import AssetsViewContext from "../../common/AssetsViewContext";
import {DeleteModalProps} from "../../common/AssetsView";
import {shortenName} from "../../common/textUtils";

export interface InEstateLifeInsuranceProps {
    profileId: string,
    assetsData: AssetsSummary;
    investorGroup: InvestorGroupType;
    showDeathBenefit?: boolean;
    onRemoveAssetClick: (deleteModalProps: DeleteModalProps) => void;
}

const LifeInsurances = ({profileId, assetsData, investorGroup, showDeathBenefit = false, onRemoveAssetClick}:
                            InEstateLifeInsuranceProps) => {

    const history = useHistory();
    const viewType = useContext(AssetsViewContext);

    const {
        getFormattedTotalPresentValue,
        getFormattedPresentValueForCurrentNetWorth,
        inEstateLifeInsurances
    } = assetListData(assetsData);

    const primaryMember = investorGroup.primaryMember;
    const partnerMember = investorGroup.partnerMember;


    const currentNetWorthLifeInsurances: CurrentNetWorthAsset[] = inEstateLifeInsurances.map((lifeInsurance) => {
        const expirationYear = lifeInsurance.expirationDate ? parseInt(lifeInsurance.expirationDate.split('-')[0]) : 0 ;

        let insuranceValue = showDeathBenefit && !lifeInsurance.isCashValueWillFundGoals ? lifeInsurance.deathBenefitValue:  lifeInsurance.cashValue;
        if(lifeInsurance.type === 'Term'  && expirationYear < (new Date().getFullYear())){
            insuranceValue = 0;
        }
        return {
                id: lifeInsurance.id,
                name: lifeInsurance.description,
                presentValue: insuranceValue,
                assetType: "lifeInsurance",
                ownershipCategory: lifeInsurance.ownershipCategory,
                memberOwnerships: lifeInsurance.memberOwnerships
            }
    })

    function generateAssetPopOverContent(insurance: LifeInsurance) {
        const ownershipDescription = getOwnershipDescription(insurance, primaryMember, partnerMember)
        return ownershipDescription ? <>{ownershipDescription}</> : undefined;
    }

    return (
        <>
            {inEstateLifeInsurances.length > 0 && <AccordionItemWithActionMenu
                uuid="LifeInsurances"
                accentColor={COLOR_LIFE_INSURANCES}
                HeaderComponent={({expanded}) =>
                    <AssetAccordionHeader
                        expanded={expanded}
                        title={'Life Insurance'}
                        caption={ showDeathBenefit ? "Value at Death" : "Cash Value"}
                        formattedPrimaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroup.primaryMember.id, currentNetWorthLifeInsurances)) : undefined}
                        formattedSecondaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroup.partnerMember?.id, currentNetWorthLifeInsurances)) : undefined}
                        formattedJointTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthLifeInsurances)) : undefined}
                        formattedTotalPresentValue={getFormattedTotalPresentValue(calculateTotalValue(inEstateLifeInsurances, showDeathBenefit))}
                        gridClassName={"current-net-worth-grid"}
                    />
                }>

                <div role="table" className="current-net-worth-grid-with-actionmenu assets-grid-table"
                     aria-label="life-insurance-table">
                    <AssetAccordionContentHeader investorGroup={investorGroup}/>

                    {inEstateLifeInsurances.map((lifeInsurance: LifeInsurance) => {
                        return <LifeInsuranceRow lifeInsurance={lifeInsurance}
                                                 actionsDisabled={false}
                                                 onClickEdit={(lifeInsuranceId: string) => {
                                                     history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditLifeInsurance/${lifeInsuranceId}`);
                                                 }}
                                                 onClickDelete={(lifeInsuranceDelete: LifeInsurance) => {
                                                     onRemoveAssetClick({
                                                         showDeleteModal: true,
                                                         modalTitle: "Asset",
                                                         assetDescription: lifeInsuranceDelete.description ?? "",
                                                         assetType: "life insurance",
                                                         assetId: lifeInsuranceDelete.id,
                                                     });
                                                 }}
                                                 key={lifeInsurance.id}
                                                 gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                 showDeathBenefit={showDeathBenefit}
                                                 renderLifeInsuranceDetails={(insurance: LifeInsurance) => {
                                                     const lifeInsuranceIfNotFundingGoals = showDeathBenefit ? insurance.deathBenefitValue : insurance.cashValue
                                                     const insuranceValue = showDeathBenefit && lifeInsurance.isCashValueWillFundGoals ? 0:  lifeInsuranceIfNotFundingGoals;

                                                     return <>
                                                         <TableCell text={shortenName(insurance.description)}
                                                                    className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                                    popoverContent={generateAssetPopOverContent(insurance)}
                                                                    popoverWidth={"288px"}
                                                         />
                                                         {investorGroup.partnerMember && <>
                                                             <TableCell
                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, insurance.memberOwnerships, insurance.ownershipCategory, insuranceValue))}
                                                                 className="textalign-right"/>
                                                             <TableCell
                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, insurance.memberOwnerships, insurance.ownershipCategory, insuranceValue))}
                                                                 className="textalign-right"/>
                                                             <TableCell
                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(insurance.ownershipCategory, insuranceValue))}
                                                                 className="textalign-right"/>
                                                         </>
                                                         }
                                                     </>
                                                 }}/>;
                    })}
                </div>
            </AccordionItemWithActionMenu>}
        </>
    );
}

export default LifeInsurances;