import {LegalAgreement} from "../../models/InvestmentProgram";
import moment from "moment";
import {DISPLAY_DATE_FORMAT} from "../../../constants/common";
import {TableCell} from "../../../components/Table/TableCell";
import {formatCurrency} from "../../../utils/format";
import React from "react";
import {getInEstateTotalValueForInvestmentProgram} from "../common/AssetSummaryCalculator";
import {Icon} from "../../../components";
import {LiabilitiesPopover} from "../Liabilities/LiabilitiesPopover";
import {shortenName} from "../../common/textUtils";

export const AssetSummaryLegalAgreementDetails = ({legalAgreement}: { legalAgreement: LegalAgreement }) => {
    const presentableAsOfDate = legalAgreement.asOfDate ? `As of ${moment(legalAgreement.asOfDate).format(DISPLAY_DATE_FORMAT)}` : '--';
    const shouldShowAccountNumber = !!legalAgreement.institution && !!legalAgreement.legalAgreementNumber && legalAgreement.legalAgreementNumber.length > 3;
    const presentableAccountNumber = `(...${legalAgreement.legalAgreementNumber?.substring(legalAgreement.legalAgreementNumber.length - 4)})`;
    const presentValue = legalAgreement.marketValue ? getInEstateTotalValueForInvestmentProgram([legalAgreement], []) : 0;

    const legalAgreementText = (
        <div role="cell" style={{paddingLeft: "0px"}}>
            <div className="cell-with-popover">
                <div className="label-15px cell-text-content paddingright-sm">
                    <span>{shortenName(legalAgreement.name)}</span>
                </div>
                {
                    legalAgreement.personalLiabilities?.length > 0 ?
                        <LiabilitiesPopover id={legalAgreement.id}
                                            liabilitySummaries={legalAgreement.personalLiabilities}/> :
                        <></>
                }
            </div>
        </div>)

    const legalAgreementSubtext = (
        <>
            {presentableAsOfDate}
            <Icon name="status_processing" className='paddingleft-md' ariaHidden={false}
                  ariaLabel="Legal Agreements are Updated"/>

        </>
    );

    return <>
        <TableCell text={legalAgreementText} subtext={legalAgreementSubtext}/>
        <TableCell text={legalAgreement.institution} subtext={shouldShowAccountNumber ? presentableAccountNumber : undefined} textSize="small"/>
        <TableCell text={legalAgreement.entityType} subtext={legalAgreement.taxStatus} textSize="small"/>
        <TableCell text={formatCurrency(presentValue)} className="textalign-right"/>
    </>;
};
